/* eslint-disable max-len */
import Grid from '@mui/material/Grid';
import React from 'react';

import { nutrientLabels } from '../../constants';
import { globalUnits } from '../../constants/settings';
import { useSettings } from '../../hooks';
import { BarChart, ChartContainer } from '../charts';
import { Skeleton } from '../shared';

const FeedsGraphs = ({
  rows,
  pickedNutrients,
  templateColumn,
  loading,
  customKey = '',
  print = false,
  currentInfo,
  isMixes = false,
  currentMixes,
}) => {
  const { formatDate, formatLabel, formatWeight } = useSettings();

  const getChartData = (nutr) => {
    if (isMixes && currentMixes?.length) {
      return currentMixes.map((mix) => {
        const lbl = nutrientLabels[nutr] || nutr;
        const formattedLabel = formatLabel(lbl);
        let value = mix.feedInfo[nutr];
        value = formatWeight(value, formattedLabel, formattedLabel, false, false, lbl);
        return {
          label: {
            short: mix?.FeedName,
            full: `${mix?.FarmName} – ${mix?.FeedName} – ${formatDate(mix?.OADate, true)}`,
          },
          value,
        };
      });
    }
    const nutrData = rows?.find((el) => el.feed_name === nutr);
    if (nutrData) {
      return Object.keys(nutrData)
        .filter((key) => key !== 'feed_name')
        .map((key) => {
          const lbl = nutrientLabels[nutr] || nutr;
          const formattedLabel = formatLabel(lbl);
          let value = nutrData[key];
          value = formatWeight(value, formattedLabel, formattedLabel, false, false, lbl);
          return {
            label: {
              short: formatDate(key, true),
              full: `${currentInfo?.farmName} – ${currentInfo?.FeedName} – ${formatDate(key, true)}`,
            },
            value,
          };
        });
    }
    return [];
  };

  const chartNutrients = pickedNutrients.filter(
    (el) => (templateColumn ? templateColumn.includes(el) : true) && rows.find((q) => q.feed_name === el)
  );

  if (loading) {
    return <Skeleton height={500} variant='rectangular' />;
  }

  return (
    chartNutrients?.length > 0 && (
      <Grid component='div' container spacing={3}>
        {chartNutrients.map((nutr, index) => (
          <Grid component='div' item key={`${nutr}_${index}${customKey}`} md={4} sm={12} xs={12}>
            <ChartContainer
              title={`${print && !isMixes ? `${currentInfo?.farmName} - ${currentInfo?.FeedName}\n` : ''}${formatLabel(
                nutrientLabels[nutr] || nutr
              )}`}
            >
              <BarChart customKey={customKey} data={getChartData(nutr)} dataSets={[{ key: 'value' }]} print={print} />
            </ChartContainer>
          </Grid>
        ))}
      </Grid>
    )
  );
};

export default FeedsGraphs;
